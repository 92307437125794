import HttpRepository from './httpRepository'
import { Media } from '~/types/api'

export default class BoardRepository extends HttpRepository {
  getBoardItems(
    params: {
      limit?: number
      offset?: number
      types?: Array<'board' | 'ad' | 'official'>
      roles?: string[]
    } = {
      limit: 2000,
      offset: 0,
      types: ['board', 'official'],
      roles: undefined
    }
  ): Promise<any> {
    return this.post('/api/board/getBoardItems', params)
  }

  getBoardItemsByCreatedUser(
    params: {
      limit?: number
      offset?: number
      types?: Array<'board' | 'ad' | 'official'>
      roles?: string[]
      createdBy: string
    } = {
      limit: 2000,
      offset: 0,
      types: ['board'],
      roles: undefined,
      createdBy: ''
    }
  ): Promise<any> {
    return this.post('/api/board/findBoardItems', params)
  }

  getBoardItem(boardPostId: string): Promise<any> {
    return this.post('/api/board/getBoardItem', {
      objectId: boardPostId
    })
  }

  getBoardAds(
    params: {
      limit?: number
      offset?: number
      displayedItems?: string[]
    } = {
      limit: 2000,
      offset: 0,
      displayedItems: []
    }
  ): Promise<any> {
    return this.post('/api/board/getBoardAds', params)
  }

  createBoardItem(
    params: {
      title?: string
      content: string
      imageUrl?: string
      territories: string[]
    } = {
      title: undefined,
      content: '',
      imageUrl: undefined,
      territories: []
    }
  ): Promise<any> {
    return this.post('/api/board/createBoardItem', {
      ...params,
      roles: ['doctor', 'ms']
    })
  }

  createBoardItemComment(boardItemId: string, comment: string): Promise<any> {
    return this.post('/api/board/createBoardItemComment', {
      boardItem: boardItemId,
      comment
    })
  }

  createBoardItemCommentMedia(boardItemId: string, media: Media): Promise<any> {
    return this.post('/api/board/createBoardItemComment', {
      boardItem: boardItemId,
      media
    })
  }

  deleteBoardItem(boardPostId: string): Promise<void> {
    return this.post('/api/board/deleteBoardItem', { objectId: boardPostId })
  }

  deleteBoardItemComment(boardCommentId: string): Promise<void> {
    return this.post('/api/board/deleteBoardItemComment', {
      objectId: boardCommentId
    })
  }

  updateBoardItemReaction(
    params: {
      boardPostId: string
      reactionType: string
    } = {
      boardPostId: '',
      reactionType: ''
    }
  ): Promise<any> {
    return this.post('/api/board/updateBoardItemReaction', {
      boardItem: params.boardPostId,
      reactionType: params.reactionType
    })
  }

  updateBoardCommentReaction(
    params: {
      boardPostId: string
      boardCommentId: string
      reactionType: string
    } = {
      boardPostId: '',
      boardCommentId: '',
      reactionType: ''
    }
  ): Promise<any> {
    return this.post('/api/board/updateBoardItemReaction', {
      boardItem: params.boardPostId,
      comment: params.boardCommentId,
      reactionType: params.reactionType
    })
  }

  findBoardItemReaction(boardPostId: string): Promise<any> {
    return this.post('/api/board/findBoardItemReaction', {
      boardItem: boardPostId
    })
  }

  findBoardItemCommentReaction(boardCommentId: string): Promise<any> {
    return this.post('/api/board/findBoardItemCommentReaction', {
      comment: boardCommentId
    })
  }
}
