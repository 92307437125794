<template>
  <VApp class="page-container">
    <VContent class="main-container">
      <Nuxt></Nuxt>
      <TabBarContainer>
        <template #default>
          <PortalTarget name="tabbar" />
        </template>
        <template #button>
          <PortalTarget name="tabbarButton" />
        </template>
      </TabBarContainer>
    </VContent>
    <PortalTarget name="outside" />
    <SnackBarContainer>
      <template #append>
        <PortalTarget name="toast-append" />
      </template>
    </SnackBarContainer>
  </VApp>
</template>

<script>
import TabBarContainer from '~/components/commons/organisms/tabBar/TabBarContainer'
import SnackBarContainer from '~/components/sp/common/SnackBarContainer.vue'

export default {
  components: {
    TabBarContainer,
    SnackBarContainer
  },
  middleware: [
    'authCheck',
    'getProfile',
    'getUnreadCounts',
    'clearRefreshInterval',
    'checkTerms',
    'fetchFrontendUserStorage'
  ]
}
</script>
<style lang="scss" scoped>
.page-container {
  background-color: white;
}
.main-container::v-deep {
  .v-content__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
