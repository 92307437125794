<template>
  <VSnackbar
    class="snackbar"
    :value="!!toast.message"
    color="rgba(44, 44, 44, 0.85)"
    top
    :timeout="duration"
  >
    <div class="snackbar-content">
      <div class="snackbar__icon">
        <Icon
          class="icon"
          :color="isSuccess ? 'white' : 'red30'"
          :size="isSuccess ? '12px' : '16px'"
          :type="isSuccess ? 'check' : 'error_outline'"
        />
      </div>
      <div class="text">{{ toast.message }}</div>
      <div class="snackbar__append ml-2">
        <div v-show="isShowAppend">
          <slot name="append" />
        </div>
      </div>
    </div>
  </VSnackbar>
</template>

<script>
import { mapState } from 'vuex'
import Icon from '~/components/commons/atoms/Icon.vue'
import { SNACKBAR_DURATION } from '~/helper/enum'
export default {
  components: {
    Icon
  },
  computed: {
    ...mapState(['toast']),
    isSuccess() {
      return this.toast?.type === 'success' || false
    },
    isShowAppend() {
      return this.toast?.options?.isShowAppend ?? false
    },
    duration() {
      const defaultDuration = this.isSuccess
        ? SNACKBAR_DURATION.SUCCESS
        : SNACKBAR_DURATION.ERROR
      return this.toast?.options?.duration ?? defaultDuration
    }
  }
}
</script>

<style scoped lang="scss">
.snackbar-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.snackbar {
  &::v-deep {
    .v-snack__wrapper {
      box-shadow: none;
    }
    .v-snack__content .v-btn:first-of-type {
      margin: 0;
    }
  }

  &__append {
    margin-right: 8px;
  }
}
.text {
  font-size: 14px;
  line-height: 1.8;
  color: #ffffff;
  font-weight: 300;
  text-align: center;
}
</style>
