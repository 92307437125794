import { Plugin } from '@nuxt/types'

const updateQueryString: Plugin = ({ app }, inject) => {
  inject(
    'updateQueryString',
    (key: string, value: string, mode: 'push' | 'replace' = 'push') => {
      const { context } = app
      const route = context.route
      const query = { ...route.query, [key]: value }
      const newUrl = `${window.location.pathname}?${new URLSearchParams(
        query
      ).toString()}`

      if (mode === 'replace') {
        window.history.replaceState(null, '', newUrl)
      } else {
        window.history.pushState(null, '', newUrl)
      }
    }
  )
  inject(
    'removeQueryString',
    (key: string, mode: 'push' | 'replace' = 'push') => {
      const { context } = app
      const route = context.route
      const query = { ...route.query }
      delete query[key]
      const queryString = new URLSearchParams(query).toString()
      const newUrl = queryString
        ? `${window.location.pathname}?${queryString}`
        : window.location.pathname

      if (mode === 'replace') {
        window.history.replaceState(null, '', newUrl)
      } else {
        window.history.pushState(null, '', newUrl)
      }
    }
  )
}

export default updateQueryString
