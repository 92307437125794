import { Plugin } from '@nuxt/types'
import { errorMessages } from '~/helper/messages'
interface ToastOptions {
  duration?: number
  isShowAppend?: string
}

export type Toast = {
  error: ({ message, error }: { message?: string; error?: any }) => void
  success: (message: string, options?: ToastOptions) => void
  errorToast: ({ message, error }: { message?: string; error?: any }) => void
}

const plugin: Plugin = (context, inject) => {
  const toast: Toast = {
    error: ({ message, error }) => {
      let errorMessage = message || errorMessages.internalServerError
      // axiosのエラーを拾うため
      if (!message && error && error.response && error.response.data) {
        errorMessage = error.response.data
      }
      window.alert(errorMessage)
    },
    success: (message, options) => {
      context.store.dispatch('showSuccessToast', {
        message,
        options
      })
    },
    errorToast: ({ message, error }) => {
      const responseErrorMessage = error?.response?.data
      const errorMessage =
        message || responseErrorMessage || errorMessages.internalServerError
      context.store.dispatch('showErrorToast', { message: errorMessage })
    }
  }

  context.$toast = toast
  inject('toast', toast)
}

export default plugin
