import { NuxtState } from '@nuxt/types/app'
import get from 'lodash.get'
import { Route } from 'vue-router'
import constant from '~/helper/constant'
import { User } from '~/types/api'
import { DoctorCategory } from '~/types/doctor'

interface BoardEventParams {
  boardId: string
  type: string
  title?: string
  body: string
  publisher: string
  url?: string
  territories: string
}
interface BoardAdEventParams {
  boardAdId: string
  adCode: string
  title?: string
  body: string
  publisher: string
  url?: string
  territories: string
}

export class FirebaseRepository {
  private firebaseClient: firebase.default.app.App

  constructor(firebaseClient: firebase.default.app.App) {
    this.firebaseClient = firebaseClient
  }

  setUserProperties(
    currentUser: Partial<User>,
    doctorCategory: DoctorCategory[],
    hospitalName: string,
    hospitalPrefecture: string,
    hospitalCity: string
  ) {
    const {
      objectId,
      category2,
      certificationYear,
      gender,
      route,
      role
    } = currentUser

    const subcategory1 = get(doctorCategory, '[0].subCategoryId', null)
    const subcategory2 = get(doctorCategory, '[1].subCategoryId', null)
    const subcategory3 = get(doctorCategory, '[2].subCategoryId', null)
    const subcategory4 = get(doctorCategory, '[3].subCategoryId', null)
    const subcategory5 = get(doctorCategory, '[4].subCategoryId', null)

    const analyticsData = {
      user_object_id: objectId,
      category: category2,
      certification_year: certificationYear,
      gender,
      route,
      role,
      subcategory1,
      subcategory2,
      subcategory3,
      subcategory4,
      subcategory5,
      hospital_name: hospitalName,
      hospital_prefecture: hospitalPrefecture,
      hospital_city: hospitalCity
    }

    // see https://docs.google.com/spreadsheets/d/1j3O0FlFqvOe8tOhI3LJVYdUmOzOIVnop_B8-qVXthis/edit#gid=833798215
    this.firebaseClient.analytics().setUserProperties(analyticsData)
  }

  viewEvent(to: Route, store: NuxtState) {
    if (!to || !to.name || !to.query) return

    // urlの/は-に変換される, topの/はindexになる /_idは-idになる
    let view = constant.firebaseViewEvents[to.name]

    if (to.name === 'hospitals-edit-open_bed-id' && to.params.id === 'new') {
      view = constant.firebaseViewEvents['hospitals-edit-open_bed-new']
    }

    if (!view) return

    const params: Partial<{
      mailType: string
      id: string
      source?: string
      medium?: string
    }> = {}
    if (to.query.mailType) {
      params.mailType = to.query.mailType.toString()
    }

    // _idを持っているpathであれば登録する
    if (to.params.id && to.params.id !== 'new') {
      params.id = to.params.id
    }

    // lists-doctors-add のqueryに含まれている doctorIdをログ登録に含める
    if (to.query.doctorId) {
      params.id = to.query.doctorId.toString()
    }

    // lists-hospitals-add のqueryに含まれている hospitalIdをログ登録に含める
    if (to.query.hospitalId) {
      params.id = to.query.hospitalId.toString()
    }

    if (to.name === 'hospitals-edit-open_bed-id' && to.params.id === 'new') {
      params.id = 'new'
    }

    // 下記配列に含まれるpathの際にはidがURLにないが、storeにあるhospitalIdをログ登録する
    if (
      [
        'hospitals-edit',
        'hospitals-edit-bed',
        'hospitals-edit-medical_staff',
        'hospitals-edit-open_bed',
        'hospitals-edit-outpatient',
        'hospitals-edit-outpatient-edit',
        'hospitals-edit-patient_date',
        'hospitals-edit-result'
      ].includes(to.name)
    ) {
      params.id = store.state.profile.belongTo
        ? store.state.profile.belongTo.hospitalId
        : store.state.profile.hospitals[0]?.hospitalId
    }

    if (to.query.utm_source) {
      params.source = to.query.utm_source.toString()
    }

    if (to.query.utm_medium) {
      params.medium = to.query.utm_medium.toString()
    }

    this.firebaseClient.analytics().logEvent(view, params)
  }

  // ボード投稿のインプレッションを計測する
  boardImpressionEvent(params: BoardEventParams) {
    this.firebaseClient.analytics().logEvent('board_impression', {
      board_id: params.boardId,
      type: params?.type ?? 'official',
      title: params?.title,
      body: params.body,
      publisher: params.publisher,
      territories: params.territories
    })
  }

  // ボード投稿の続きを読むのクリックを計測する
  boardClickReadMoreEvent(params: BoardEventParams) {
    this.firebaseClient.analytics().logEvent('board_click_read_more', {
      board_id: params.boardId,
      type: params?.type ?? 'official',
      title: params?.title,
      body: params.body,
      publisher: params.publisher,
      territories: params.territories
    })
  }

  // ボード投稿の本文内のリンクのクリックを計測する
  boardClickDetailTextLinkEvent(params: BoardEventParams) {
    this.firebaseClient.analytics().logEvent('board_click_detail_text_link', {
      board_id: params.boardId,
      type: params?.type ?? 'official',
      title: params?.title,
      body: params.body,
      publisher: params.publisher,
      url: params.url,
      territories: params.territories
    })
  }

  // ボード広告のインプレッションを計測する
  boardAdImpressionEvent(params: BoardAdEventParams) {
    this.firebaseClient.analytics().logEvent('board_ad_impression', {
      board_ad_id: params.boardAdId,
      ad_code: params.adCode,
      title: params?.title,
      body: params.body,
      publisher: params.publisher,
      territories: params.territories
    })
  }

  // ボード広告のクリックを計測する
  boardAdClickEvent(params: BoardAdEventParams) {
    this.firebaseClient.analytics().logEvent('board_ad_click', {
      board_ad_id: params.boardAdId,
      ad_code: params.adCode,
      title: params?.title,
      body: params.body,
      publisher: params.publisher,
      url: params.url,
      territories: params.territories
    })
  }

  // ボード広告の続きを読むのクリックを計測する
  boardAdClickReadMoreEvent(params: BoardAdEventParams) {
    this.firebaseClient.analytics().logEvent('board_ad_click_read_more', {
      board_ad_id: params.boardAdId,
      ad_code: params.adCode,
      title: params?.title,
      body: params.body,
      publisher: params.publisher,
      territories: params.territories
    })
  }

  // ボード広告の本文内のリンクのクリックを計測する
  boardAdClickDetailTextLinkEvent(params: BoardAdEventParams) {
    this.firebaseClient
      .analytics()
      .logEvent('board_ad_click_detail_text_link', {
        board_ad_id: params.boardAdId,
        ad_code: params.adCode,
        title: params?.title,
        body: params.body,
        publisher: params.publisher,
        url: params.url,
        territories: params.territories
      })
  }

  boardViewDetailModalEvent(params: BoardEventParams) {
    this.firebaseClient.analytics().logEvent('board_view_detail_modal', {
      board_id: params.boardId,
      type: params?.type ?? 'board',
      title: params?.title,
      body: params.body,
      publisher: params.publisher,
      territories: params.territories
    })
  }

  boardViewDetailPageEvent(params: BoardEventParams) {
    this.firebaseClient.analytics().logEvent('board_view_detail_page', {
      board_id: params.boardId,
      type: params?.type ?? 'board',
      title: params?.title,
      body: params.body,
      publisher: params.publisher,
      territories: params.territories
    })
  }
}
