import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7b84d1b3 = () => interopDefault(import('../pages/add-home.vue' /* webpackChunkName: "pages/add-home" */))
const _926d5aa4 = () => interopDefault(import('../pages/agree-terms.vue' /* webpackChunkName: "pages/agree-terms" */))
const _35c23548 = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _a82650ec = () => interopDefault(import('../pages/doctor/index.vue' /* webpackChunkName: "pages/doctor/index" */))
const _4b20e26c = () => interopDefault(import('../pages/group/index.vue' /* webpackChunkName: "pages/group/index" */))
const _5c080d78 = () => interopDefault(import('../pages/hospitals/index.vue' /* webpackChunkName: "pages/hospitals/index" */))
const _b93234f8 = () => interopDefault(import('../pages/invitation/index.vue' /* webpackChunkName: "pages/invitation/index" */))
const _df22051e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _13ff4da6 = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _953aa434 = () => interopDefault(import('../pages/notices/index.vue' /* webpackChunkName: "pages/notices/index" */))
const _7eadb456 = () => interopDefault(import('../pages/notification/index.vue' /* webpackChunkName: "pages/notification/index" */))
const _4b5d92b7 = () => interopDefault(import('../pages/posting_information.vue' /* webpackChunkName: "pages/posting_information" */))
const _2bd23bb1 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _006eb0de = () => interopDefault(import('../pages/regist-confirm.vue' /* webpackChunkName: "pages/regist-confirm" */))
const _258fbe60 = () => interopDefault(import('../pages/retry-register.vue' /* webpackChunkName: "pages/retry-register" */))
const _db369ffa = () => interopDefault(import('../pages/rooms/index.vue' /* webpackChunkName: "pages/rooms/index" */))
const _843a6b60 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _6c6ac480 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _70c5188f = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _e4fb7e72 = () => interopDefault(import('../pages/unreceived_auth_code.vue' /* webpackChunkName: "pages/unreceived_auth_code" */))
const _2101c8ea = () => interopDefault(import('../pages/welcome.vue' /* webpackChunkName: "pages/welcome" */))
const _93649aae = () => interopDefault(import('../pages/business-card/holder/index.vue' /* webpackChunkName: "pages/business-card/holder/index" */))
const _86fa537e = () => interopDefault(import('../pages/business-card/requests/index.vue' /* webpackChunkName: "pages/business-card/requests/index" */))
const _3021d2f0 = () => interopDefault(import('../pages/contact/complete.vue' /* webpackChunkName: "pages/contact/complete" */))
const _58de95d9 = () => interopDefault(import('../pages/contact/confirm.vue' /* webpackChunkName: "pages/contact/confirm" */))
const _9302cf0a = () => interopDefault(import('../pages/contact/form.vue' /* webpackChunkName: "pages/contact/form" */))
const _11fb2ddb = () => interopDefault(import('../pages/doctor/new/index.vue' /* webpackChunkName: "pages/doctor/new/index" */))
const _7d6cf320 = () => interopDefault(import('../pages/doctor/search.vue' /* webpackChunkName: "pages/doctor/search" */))
const _1606ded0 = () => interopDefault(import('../pages/group/new.vue' /* webpackChunkName: "pages/group/new" */))
const _5c1d320b = () => interopDefault(import('../pages/hospitals/edit/index.vue' /* webpackChunkName: "pages/hospitals/edit/index" */))
const _194208a6 = () => interopDefault(import('../pages/hospitals/search.vue' /* webpackChunkName: "pages/hospitals/search" */))
const _19f5cdf7 = () => interopDefault(import('../pages/invitation/complete.vue' /* webpackChunkName: "pages/invitation/complete" */))
const _e61f306c = () => interopDefault(import('../pages/lists/hospitals/index.vue' /* webpackChunkName: "pages/lists/hospitals/index" */))
const _746387ae = () => interopDefault(import('../pages/lists/new.vue' /* webpackChunkName: "pages/lists/new" */))
const _57cf6aca = () => interopDefault(import('../pages/medical/confirm.vue' /* webpackChunkName: "pages/medical/confirm" */))
const _4ddf7133 = () => interopDefault(import('../pages/medical/profile.vue' /* webpackChunkName: "pages/medical/profile" */))
const _8b40a004 = () => interopDefault(import('../pages/medical/signup.vue' /* webpackChunkName: "pages/medical/signup" */))
const _9acd6304 = () => interopDefault(import('../pages/ms/group.vue' /* webpackChunkName: "pages/ms/group" */))
const _20eb5bfc = () => interopDefault(import('../pages/ms/search/index.vue' /* webpackChunkName: "pages/ms/search/index" */))
const _17a94084 = () => interopDefault(import('../pages/mypage/search.vue' /* webpackChunkName: "pages/mypage/search" */))
const _25a67637 = () => interopDefault(import('../pages/notices/new/index.vue' /* webpackChunkName: "pages/notices/new/index" */))
const _380aac11 = () => interopDefault(import('../pages/rooms/new.vue' /* webpackChunkName: "pages/rooms/new" */))
const _60910fa5 = () => interopDefault(import('../pages/setting/mail_magazine.vue' /* webpackChunkName: "pages/setting/mail_magazine" */))
const _075debbc = () => interopDefault(import('../pages/top/medical/index.vue' /* webpackChunkName: "pages/top/medical/index" */))
const _6717ad70 = () => interopDefault(import('../pages/top/ms.vue' /* webpackChunkName: "pages/top/ms" */))
const _f961a6ee = () => interopDefault(import('../pages/doctor/new/confirm.vue' /* webpackChunkName: "pages/doctor/new/confirm" */))
const _79bb8816 = () => interopDefault(import('../pages/doctor/new/profile/index.vue' /* webpackChunkName: "pages/doctor/new/profile/index" */))
const _be63277a = () => interopDefault(import('../pages/doctor/new/select.vue' /* webpackChunkName: "pages/doctor/new/select" */))
const _0b346038 = () => interopDefault(import('../pages/group/member/add.vue' /* webpackChunkName: "pages/group/member/add" */))
const _be94eb0c = () => interopDefault(import('../pages/hospitals/edit/bed.vue' /* webpackChunkName: "pages/hospitals/edit/bed" */))
const _c636902a = () => interopDefault(import('../pages/hospitals/edit/medical_staff.vue' /* webpackChunkName: "pages/hospitals/edit/medical_staff" */))
const _59129aa6 = () => interopDefault(import('../pages/hospitals/edit/open_bed/index.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/index" */))
const _08293811 = () => interopDefault(import('../pages/hospitals/edit/outpatient/index.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/index" */))
const _e61d7f02 = () => interopDefault(import('../pages/hospitals/edit/patient_date.vue' /* webpackChunkName: "pages/hospitals/edit/patient_date" */))
const _48e9ee98 = () => interopDefault(import('../pages/hospitals/edit/result.vue' /* webpackChunkName: "pages/hospitals/edit/result" */))
const _216e1019 = () => interopDefault(import('../pages/lists/hospitals/add.vue' /* webpackChunkName: "pages/lists/hospitals/add" */))
const _028c2f67 = () => interopDefault(import('../pages/notices/new/select.vue' /* webpackChunkName: "pages/notices/new/select" */))
const _26b13674 = () => interopDefault(import('../pages/doctor/new/profile/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/new/profile/doctorExpertCategory" */))
const _9681090c = () => interopDefault(import('../pages/doctor/new/profile/pending.vue' /* webpackChunkName: "pages/doctor/new/profile/pending" */))
const _d615031c = () => interopDefault(import('../pages/doctor/new/profile/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/searchPaperMeshList" */))
const _7c10f0ba = () => interopDefault(import('../pages/doctor/new/profile/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/surgeryMeshList" */))
const _4f56449a = () => interopDefault(import('../pages/doctor/new/profile/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/new/profile/unacceptableMeshList" */))
const _37f3251b = () => interopDefault(import('../pages/hospitals/edit/outpatient/edit.vue' /* webpackChunkName: "pages/hospitals/edit/outpatient/edit" */))
const _3b92ef88 = () => interopDefault(import('../pages/doctor/new/profile/editHospital/_id.vue' /* webpackChunkName: "pages/doctor/new/profile/editHospital/_id" */))
const _6c99e2e4 = () => interopDefault(import('../pages/hospitals/edit/open_bed/_id.vue' /* webpackChunkName: "pages/hospitals/edit/open_bed/_id" */))
const _9f045e04 = () => interopDefault(import('../pages/group/notice/_id/index.vue' /* webpackChunkName: "pages/group/notice/_id/index" */))
const _7fac569c = () => interopDefault(import('../pages/lists/hospitals/_id.vue' /* webpackChunkName: "pages/lists/hospitals/_id" */))
const _2d0f5d19 = () => interopDefault(import('../pages/board/_id.vue' /* webpackChunkName: "pages/board/_id" */))
const _2a1b1d75 = () => interopDefault(import('../pages/doctor/_id/index.vue' /* webpackChunkName: "pages/doctor/_id/index" */))
const _5df034b5 = () => interopDefault(import('../pages/group/_id/index.vue' /* webpackChunkName: "pages/group/_id/index" */))
const _b80106a8 = () => interopDefault(import('../pages/hospitals/_id.vue' /* webpackChunkName: "pages/hospitals/_id" */))
const _7fb878ec = () => interopDefault(import('../pages/invitation/_id.vue' /* webpackChunkName: "pages/invitation/_id" */))
const _3d0329bc = () => interopDefault(import('../pages/ms/_id/index.vue' /* webpackChunkName: "pages/ms/_id/index" */))
const _3dc665d1 = () => interopDefault(import('../pages/notices/_id/index.vue' /* webpackChunkName: "pages/notices/_id/index" */))
const _15a8d43e = () => interopDefault(import('../pages/notification/_id.vue' /* webpackChunkName: "pages/notification/_id" */))
const _340c088c = () => interopDefault(import('../pages/papers/_id.vue' /* webpackChunkName: "pages/papers/_id" */))
const _30dfdd24 = () => interopDefault(import('../pages/rooms/_id/index.vue' /* webpackChunkName: "pages/rooms/_id/index" */))
const _0172047a = () => interopDefault(import('../pages/doctor/_id/edit/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/index" */))
const _7cc803f7 = () => interopDefault(import('../pages/group/_id/edit.vue' /* webpackChunkName: "pages/group/_id/edit" */))
const _b2dc4e92 = () => interopDefault(import('../pages/group/_id/history.vue' /* webpackChunkName: "pages/group/_id/history" */))
const _11174c8a = () => interopDefault(import('../pages/group/_id/member/index.vue' /* webpackChunkName: "pages/group/_id/member/index" */))
const _72d98c93 = () => interopDefault(import('../pages/ms/_id/edit/index.vue' /* webpackChunkName: "pages/ms/_id/edit/index" */))
const _0adcd4c4 = () => interopDefault(import('../pages/notices/_id/edit/index.vue' /* webpackChunkName: "pages/notices/_id/edit/index" */))
const _5865a7c4 = () => interopDefault(import('../pages/rooms/_id/edit.vue' /* webpackChunkName: "pages/rooms/_id/edit" */))
const _e508e010 = () => interopDefault(import('../pages/rooms/_id/members/index.vue' /* webpackChunkName: "pages/rooms/_id/members/index" */))
const _3bf5e486 = () => interopDefault(import('../pages/doctor/_id/edit/category.vue' /* webpackChunkName: "pages/doctor/_id/edit/category" */))
const _72be9962 = () => interopDefault(import('../pages/doctor/_id/edit/doctorExpertCategory.vue' /* webpackChunkName: "pages/doctor/_id/edit/doctorExpertCategory" */))
const _a0129b78 = () => interopDefault(import('../pages/doctor/_id/edit/email.vue' /* webpackChunkName: "pages/doctor/_id/edit/email" */))
const _555a9eb2 = () => interopDefault(import('../pages/doctor/_id/edit/papers/index.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/index" */))
const _37057a37 = () => interopDefault(import('../pages/doctor/_id/edit/searchPaperMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/searchPaperMeshList" */))
const _34b162a6 = () => interopDefault(import('../pages/doctor/_id/edit/specialty.vue' /* webpackChunkName: "pages/doctor/_id/edit/specialty" */))
const _a003a430 = () => interopDefault(import('../pages/doctor/_id/edit/surgeryMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/surgeryMeshList" */))
const _78445a8e = () => interopDefault(import('../pages/doctor/_id/edit/unacceptableMeshList.vue' /* webpackChunkName: "pages/doctor/_id/edit/unacceptableMeshList" */))
const _f5f6a6d4 = () => interopDefault(import('../pages/group/_id/notice/new.vue' /* webpackChunkName: "pages/group/_id/notice/new" */))
const _69f60640 = () => interopDefault(import('../pages/notices/_id/edit/select.vue' /* webpackChunkName: "pages/notices/_id/edit/select" */))
const _51616bc7 = () => interopDefault(import('../pages/rooms/_id/members/add.vue' /* webpackChunkName: "pages/rooms/_id/members/add" */))
const _d583110a = () => interopDefault(import('../pages/doctor/_id/edit/papers/delete.vue' /* webpackChunkName: "pages/doctor/_id/edit/papers/delete" */))
const _0054399e = () => interopDefault(import('../pages/doctor/_id/edit/hospitals/_index.vue' /* webpackChunkName: "pages/doctor/_id/edit/hospitals/_index" */))
const _68d4ef08 = () => interopDefault(import('../pages/rooms/_id/medias/_mediaId.vue' /* webpackChunkName: "pages/rooms/_id/medias/_mediaId" */))
const _72714b4c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/add-home",
    component: _7b84d1b3,
    name: "add-home"
  }, {
    path: "/agree-terms",
    component: _926d5aa4,
    name: "agree-terms"
  }, {
    path: "/confirm",
    component: _35c23548,
    name: "confirm"
  }, {
    path: "/doctor",
    component: _a82650ec,
    name: "doctor"
  }, {
    path: "/group",
    component: _4b20e26c,
    name: "group"
  }, {
    path: "/hospitals",
    component: _5c080d78,
    name: "hospitals"
  }, {
    path: "/invitation",
    component: _b93234f8,
    name: "invitation"
  }, {
    path: "/login",
    component: _df22051e,
    name: "login"
  }, {
    path: "/mypage",
    component: _13ff4da6,
    name: "mypage"
  }, {
    path: "/notices",
    component: _953aa434,
    name: "notices"
  }, {
    path: "/notification",
    component: _7eadb456,
    name: "notification"
  }, {
    path: "/posting_information",
    component: _4b5d92b7,
    name: "posting_information"
  }, {
    path: "/profile",
    component: _2bd23bb1,
    name: "profile"
  }, {
    path: "/regist-confirm",
    component: _006eb0de,
    name: "regist-confirm"
  }, {
    path: "/retry-register",
    component: _258fbe60,
    name: "retry-register"
  }, {
    path: "/rooms",
    component: _db369ffa,
    name: "rooms"
  }, {
    path: "/search",
    component: _843a6b60,
    name: "search"
  }, {
    path: "/signup",
    component: _6c6ac480,
    name: "signup"
  }, {
    path: "/terms",
    component: _70c5188f,
    name: "terms"
  }, {
    path: "/unreceived_auth_code",
    component: _e4fb7e72,
    name: "unreceived_auth_code"
  }, {
    path: "/welcome",
    component: _2101c8ea,
    name: "welcome"
  }, {
    path: "/business-card/holder",
    component: _93649aae,
    name: "business-card-holder"
  }, {
    path: "/business-card/requests",
    component: _86fa537e,
    name: "business-card-requests"
  }, {
    path: "/contact/complete",
    component: _3021d2f0,
    name: "contact-complete"
  }, {
    path: "/contact/confirm",
    component: _58de95d9,
    name: "contact-confirm"
  }, {
    path: "/contact/form",
    component: _9302cf0a,
    name: "contact-form"
  }, {
    path: "/doctor/new",
    component: _11fb2ddb,
    name: "doctor-new"
  }, {
    path: "/doctor/search",
    component: _7d6cf320,
    name: "doctor-search"
  }, {
    path: "/group/new",
    component: _1606ded0,
    name: "group-new"
  }, {
    path: "/hospitals/edit",
    component: _5c1d320b,
    name: "hospitals-edit"
  }, {
    path: "/hospitals/search",
    component: _194208a6,
    name: "hospitals-search"
  }, {
    path: "/invitation/complete",
    component: _19f5cdf7,
    name: "invitation-complete"
  }, {
    path: "/lists/hospitals",
    component: _e61f306c,
    name: "lists-hospitals"
  }, {
    path: "/lists/new",
    component: _746387ae,
    name: "lists-new"
  }, {
    path: "/medical/confirm",
    component: _57cf6aca,
    name: "medical-confirm"
  }, {
    path: "/medical/profile",
    component: _4ddf7133,
    name: "medical-profile"
  }, {
    path: "/medical/signup",
    component: _8b40a004,
    name: "medical-signup"
  }, {
    path: "/ms/group",
    component: _9acd6304,
    name: "ms-group"
  }, {
    path: "/ms/search",
    component: _20eb5bfc,
    name: "ms-search"
  }, {
    path: "/mypage/search",
    component: _17a94084,
    name: "mypage-search"
  }, {
    path: "/notices/new",
    component: _25a67637,
    name: "notices-new"
  }, {
    path: "/rooms/new",
    component: _380aac11,
    name: "rooms-new"
  }, {
    path: "/setting/mail_magazine",
    component: _60910fa5,
    name: "setting-mail_magazine"
  }, {
    path: "/top/medical",
    component: _075debbc,
    name: "top-medical"
  }, {
    path: "/top/ms",
    component: _6717ad70,
    name: "top-ms"
  }, {
    path: "/doctor/new/confirm",
    component: _f961a6ee,
    name: "doctor-new-confirm"
  }, {
    path: "/doctor/new/profile",
    component: _79bb8816,
    name: "doctor-new-profile"
  }, {
    path: "/doctor/new/select",
    component: _be63277a,
    name: "doctor-new-select"
  }, {
    path: "/group/member/add",
    component: _0b346038,
    name: "group-member-add"
  }, {
    path: "/hospitals/edit/bed",
    component: _be94eb0c,
    name: "hospitals-edit-bed"
  }, {
    path: "/hospitals/edit/medical_staff",
    component: _c636902a,
    name: "hospitals-edit-medical_staff"
  }, {
    path: "/hospitals/edit/open_bed",
    component: _59129aa6,
    name: "hospitals-edit-open_bed"
  }, {
    path: "/hospitals/edit/outpatient",
    component: _08293811,
    name: "hospitals-edit-outpatient"
  }, {
    path: "/hospitals/edit/patient_date",
    component: _e61d7f02,
    name: "hospitals-edit-patient_date"
  }, {
    path: "/hospitals/edit/result",
    component: _48e9ee98,
    name: "hospitals-edit-result"
  }, {
    path: "/lists/hospitals/add",
    component: _216e1019,
    name: "lists-hospitals-add"
  }, {
    path: "/notices/new/select",
    component: _028c2f67,
    name: "notices-new-select"
  }, {
    path: "/doctor/new/profile/doctorExpertCategory",
    component: _26b13674,
    name: "doctor-new-profile-doctorExpertCategory"
  }, {
    path: "/doctor/new/profile/pending",
    component: _9681090c,
    name: "doctor-new-profile-pending"
  }, {
    path: "/doctor/new/profile/searchPaperMeshList",
    component: _d615031c,
    name: "doctor-new-profile-searchPaperMeshList"
  }, {
    path: "/doctor/new/profile/surgeryMeshList",
    component: _7c10f0ba,
    name: "doctor-new-profile-surgeryMeshList"
  }, {
    path: "/doctor/new/profile/unacceptableMeshList",
    component: _4f56449a,
    name: "doctor-new-profile-unacceptableMeshList"
  }, {
    path: "/hospitals/edit/outpatient/edit",
    component: _37f3251b,
    name: "hospitals-edit-outpatient-edit"
  }, {
    path: "/doctor/new/profile/editHospital/:id?",
    component: _3b92ef88,
    name: "doctor-new-profile-editHospital-id"
  }, {
    path: "/hospitals/edit/open_bed/:id",
    component: _6c99e2e4,
    name: "hospitals-edit-open_bed-id"
  }, {
    path: "/group/notice/:id",
    component: _9f045e04,
    name: "group-notice-id"
  }, {
    path: "/lists/hospitals/:id",
    component: _7fac569c,
    name: "lists-hospitals-id"
  }, {
    path: "/board/:id?",
    component: _2d0f5d19,
    name: "board-id"
  }, {
    path: "/doctor/:id",
    component: _2a1b1d75,
    name: "doctor-id"
  }, {
    path: "/group/:id",
    component: _5df034b5,
    name: "group-id"
  }, {
    path: "/hospitals/:id",
    component: _b80106a8,
    name: "hospitals-id"
  }, {
    path: "/invitation/:id",
    component: _7fb878ec,
    name: "invitation-id"
  }, {
    path: "/ms/:id",
    component: _3d0329bc,
    name: "ms-id"
  }, {
    path: "/notices/:id",
    component: _3dc665d1,
    name: "notices-id"
  }, {
    path: "/notification/:id",
    component: _15a8d43e,
    name: "notification-id"
  }, {
    path: "/papers/:id?",
    component: _340c088c,
    name: "papers-id"
  }, {
    path: "/rooms/:id",
    component: _30dfdd24,
    name: "rooms-id"
  }, {
    path: "/doctor/:id/edit",
    component: _0172047a,
    name: "doctor-id-edit"
  }, {
    path: "/group/:id/edit",
    component: _7cc803f7,
    name: "group-id-edit"
  }, {
    path: "/group/:id/history",
    component: _b2dc4e92,
    name: "group-id-history"
  }, {
    path: "/group/:id/member",
    component: _11174c8a,
    name: "group-id-member"
  }, {
    path: "/ms/:id?/edit",
    component: _72d98c93,
    name: "ms-id-edit"
  }, {
    path: "/notices/:id/edit",
    component: _0adcd4c4,
    name: "notices-id-edit"
  }, {
    path: "/rooms/:id/edit",
    component: _5865a7c4,
    name: "rooms-id-edit"
  }, {
    path: "/rooms/:id/members",
    component: _e508e010,
    name: "rooms-id-members"
  }, {
    path: "/doctor/:id/edit/category",
    component: _3bf5e486,
    name: "doctor-id-edit-category"
  }, {
    path: "/doctor/:id/edit/doctorExpertCategory",
    component: _72be9962,
    name: "doctor-id-edit-doctorExpertCategory"
  }, {
    path: "/doctor/:id/edit/email",
    component: _a0129b78,
    name: "doctor-id-edit-email"
  }, {
    path: "/doctor/:id/edit/papers",
    component: _555a9eb2,
    name: "doctor-id-edit-papers"
  }, {
    path: "/doctor/:id/edit/searchPaperMeshList",
    component: _37057a37,
    name: "doctor-id-edit-searchPaperMeshList"
  }, {
    path: "/doctor/:id/edit/specialty",
    component: _34b162a6,
    name: "doctor-id-edit-specialty"
  }, {
    path: "/doctor/:id/edit/surgeryMeshList",
    component: _a003a430,
    name: "doctor-id-edit-surgeryMeshList"
  }, {
    path: "/doctor/:id/edit/unacceptableMeshList",
    component: _78445a8e,
    name: "doctor-id-edit-unacceptableMeshList"
  }, {
    path: "/group/:id/notice/new",
    component: _f5f6a6d4,
    name: "group-id-notice-new"
  }, {
    path: "/notices/:id/edit/select",
    component: _69f60640,
    name: "notices-id-edit-select"
  }, {
    path: "/rooms/:id/members/add",
    component: _51616bc7,
    name: "rooms-id-members-add"
  }, {
    path: "/doctor/:id/edit/papers/delete",
    component: _d583110a,
    name: "doctor-id-edit-papers-delete"
  }, {
    path: "/doctor/:id/edit/hospitals/:index",
    component: _0054399e,
    name: "doctor-id-edit-hospitals"
  }, {
    path: "/rooms/:id/medias/:mediaId?",
    component: _68d4ef08,
    name: "rooms-id-medias-mediaId"
  }, {
    path: "/",
    component: _72714b4c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
